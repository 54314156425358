<template>
  <div class="ticket_view">
    <page-header :items="items">
      <template #action>
        <loading-button @click="updateTicket" :Loading="isLoading">
          <span>{{ $t("Update") + " " + $t("Ticket") }}</span>
        </loading-button>
      </template>
    </page-header>
    <div class="row">
      <div class="col-12">
        <div class="card mb-0">
          <div class="card-body">
            <div class="card" v-if="isReportedCase">
              <div
                class="card-header d-flex align-items-center justify-content-start gap-2 justify-content-between action"
              >
                <div class="d-flex align-items-center gap-2">
                  <h3 class="m-0">
                    {{ $t("Connected Case") }}
                  </h3>
                  <p class="status-tag" v-if="ticket.case.status">
                    {{ ticket.case.status }}
                  </p>
                </div>
                <b-nav-item-dropdown
                  right
                  toggle-class="d-flex align-items-center"
                  class=""
                >
                  <template #button-content>
                    <div class="">
                      <ElementsIcon v-bind:icon="'barVerticalIcon'" />
                    </div>
                  </template>

                  <b-dropdown-item
                    v-if="caseItem.files['upper_portal_original'] || caseItem.files['lower_portal_original']"
                    link-class="d-flex align-items-center"
                    @click="
                      downloadFiles([
                        ticket.case.files['upper_portal_original'],
                        ticket.case.files['lower_portal_original'],
                      ])
                    "
                  >
                    <feather-icon size="16" icon="DownloadIcon" class="mr-50" />

                    <span>
                      {{ $t("Download Original") }}
                    </span>
                  </b-dropdown-item>
                  <div v-if="ticket.case.results">
                    <b-dropdown-item
                      @click="
                        downloadFiles([
                          ticket.case.results['upper_rotated'] ||
                            ticket.case.results['upper'],
                          ticket.case.results['lower_rotated'] ||
                            ticket.case.results['lower'],
                        ])
                      "
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon
                        size="16"
                        icon="DownloadIcon"
                        class="mr-50"
                      />

                      <span>
                        {{ $t("Download Result") }}
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="
                        downloadFiles([
                          ticket.case.results['upper'],
                          ticket.case.results['lower'],
                        ])
                      "
                      link-class="d-flex align-items-center"
                    >
                      <feather-icon
                        size="16"
                        icon="DownloadIcon"
                        class="mr-50"
                      />

                      <span>
                        {{ $t("Download Without Rotation") }}
                      </span>
                    </b-dropdown-item>
                  </div>
                </b-nav-item-dropdown>
              </div>
              <div class="card-body px-3">
                <div class="invoice-details">
                  <div class="row align-items-start">
                    <div class="col-6">
                      <div
                        class="d-flex align-items-center justify-content-between"
                        style="margin-bottom: 8px"
                      >
                        <h4>{{ $t("Case ID") }}</h4>
                        <p>
                          {{ ticket.case.id }}
                        </p>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-between"
                        style="margin-bottom: 8px"
                      >
                        <h4>{{ $t("Case Name") }}</h4>
                        <p v-if="ticket.case && ticket.case.name">
                          {{ ticket.case.name }}
                        </p>
                        <p v-else>-</p>
                      </div>
                      <div
                        class="d-flex align-items-center justify-content-between"
                      >
                        <h4>{{ $t("Case Status") }}</h4>
                        <p v-if="ticket.case && ticket.case.caseStatus">
                          {{ ticket.case.caseStatus }}
                        </p>
                        <p v-else>-</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card mt-1">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label=""
                        :title="$t('Ticket')"
                      />
                      <div class="form-control disabled">
                        <p class="m-0" v-if="!!ticket.ticketNumber">
                          {{ ticket.ticketNumber }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label=""
                        :title="$t('Created')"
                      />
                      <div class="form-control disabled">
                        <p v-if="ticket.createdAt" class="m-0">
                          {{ formatTime(ticket.createdAt || "") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div
                      v-if="
                        !!calculateDeadline &&
                        !hasDueDatePassed &&
                        ticket.status !== 'resolved'
                      "
                      class="form-group"
                    >
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Due Time')"
                      />
                      <div class="form-control disabled">
                        <p class="m-0">
                          <span class="float-right" style="font-size: 15px">
                            {{ calculateDeadline }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Status')"
                      />
                      <select class="form-control" v-model="form.status">
                        <option value="new">
                          {{ $t("New") }}
                        </option>
                        <option value="open">
                          {{ $t("Open") }}
                        </option>
                        <option value="waiting-for-response">
                          {{ $t("Waiting For Response") }}
                        </option>
                        <option value="pending">
                          {{ $t("Pending") }}
                        </option>
                        <option value="resolved">
                          {{ $t("Resolved") }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Priority')"
                      />
                      <select class="form-control" v-model="form.priority">
                        <option value="low">{{ $t("Low") }}</option>
                        <option value="normal">{{ $t("Normal") }}</option>
                        <option value="high">{{ $t("High") }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group m-0">
                      <ElLabel
                        class="form-label"
                        for-label="groups"
                        :title="$t('Reporter')"
                      />
                      <div
                        v-if="!!ticket.userId"
                        class="d-flex align-items-center"
                      >
                        <img
                          v-if="
                            userProfiles[ticket.userId] &&
                            userProfiles[ticket.userId].profile_image
                          "
                          :src="userProfiles[ticket.userId].profile_image"
                          alt=""
                          style="
                            width: 35px;
                            height: 35px;
                            object-fit: cover;
                            border-radius: 50%;
                          "
                        />
                        <h5 v-if="userProfiles[ticket.userId]" class="ml-1">
                          {{
                            (userProfiles[ticket.userId].first_name || "") +
                            " " +
                            (userProfiles[ticket.userId].last_name || "")
                          }}
                        </h5>
                      </div>
                      <div v-else class="d-flex align-items-center">
                        <img
                          style="
                            width: 35px;
                            height: 35px;
                            object-fit: cover;
                            border-radius: 50%;
                          "
                        />
                        <h5 class="ms-1"></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="chat">
              <div class="add_comm mb-2" id="Comment">
                <div
                  class="form-group m-0 editor"
                  v-show="ticket.state !== 'resolved'"
                >
                  <MarkdownEditor
                    :content="commentText || ''"
                    @change="commentText = $event"
                    :builder-images="[]"
                    :editable="true"
                    @imageAdded="addCommentImages"
                  />
                </div>
                <div v-if="hasCommentError">
                  <span class="text-danger ms-1"
                    >{{ $t("This value is required") }}.</span
                  >
                </div>
                <div class="dropzone-wrapper mt-2" v-if="enableAttachment">
                  <div class="dropzone" v-show="ticket.state !== 'resolved'">
                    <vue-dropzone
                      id="dropzone"
                      ref="myDropzone"
                      :disabled="ticket.state === 'resolved' || false"
                      :options="dropzoneOptions"
                    />
                  </div>
                  <show-files
                    :commentFiles="commentFiles"
                    @remove="deleteUpload"
                  />
                </div>
                <div
                  class="add_bottom d-flex mt-1 gap-2"
                  :class="{
                    'justify-content-end': ticket.state === 'resolved',
                  }"
                >
                  <div
                    class="d-flex align-items-center gap-2"
                    v-if="ticket.state !== 'resolved'"
                  >
                    <button
                      :title="ticket.state === 'resolved' ? 'disabled' : ''"
                      @click="createComment"
                      :disabled="ticket.state === 'resolved' || !commentBtn"
                      class="btn btn-primary gap-1 waves-effect waves-light"
                    >
                      <feather-icon size="18" icon="PlusIcon" />
                      {{ $t(`Create Public Answer`) }}
                    </button>
                    <div :title="ticket.state === 'resolved' ? 'disabled' : ''">
                      <label
                        for="ticket-file"
                        class="btn btn-primary waves-effect waves-light custom-file-upload"
                        ><feather-icon size="18" icon="UploadIcon" />
                        <span class="">{{ $t("Add Attachment") }}</span>
                      </label>
                      <button
                        id="ticket-file"
                        class="form-control"
                        :disabled="ticket.state === 'resolved'"
                        @click="openDropZone()"
                        style="display: none"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
              <template v-if="comments && comments.length > 0">
                <div
                  v-for="comment in comments || []"
                  :key="'comment-' + comment.id"
                  :id="'comment-' + comment.id"
                  :class="['comment']"
                >
                  <div class="comment-header">
                    <div class="comment-user">
                      <img
                        :title="comment.user.email || ''"
                        v-if="
                          !!userProfiles[comment.userId] &&
                          !!userProfiles[comment.userId].profile_image
                        "
                        :src="userProfiles[comment.userId].profile_image"
                        class="comment-user-img"
                      />
                      <img
                        v-else
                        :title="
                          (userProfiles[comment.userId] &&
                            userProfiles[comment.userId].email) ||
                          ''
                        "
                        src="../../assets/images/default.png"
                        class="comment-user-img"
                      />
                      <div class="comment-user-title">
                        <h3
                          v-if="
                            userProfiles[comment.userId] &&
                            (userProfiles[comment.userId].first_name ||
                              userProfiles[comment.userId].last_name)
                          "
                        >
                          {{
                            (userProfiles[comment.userId].first_name || "") +
                            " " +
                            (userProfiles[comment.userId].last_name || "")
                          }}
                        </h3>
                        <h3 v-else>{{ $t("No Name") }}</h3>
                      </div>
                    </div>
                    <!-- <div
                      v-if="
                        user &&
                        comment &&
                        ticket &&
                        user.id === comment.userId &&
                        comment.isArchived === 0 &&
                        ticket.isArchived === 0
                      "
                      class="dropdown"
                    >
                      <b-nav-item-dropdown
                        right
                        toggle-class="d-flex align-items-center dropdown-action-link"
                        class="dropdown-user"
                      >
                        <template #button-content>
                          <div class="">
                            <feather-icon size="20" icon="MoreVerticalIcon" />
                          </div>
                        </template>

                        <b-dropdown-item
                          link-class="d-flex align-items-center gap-1"
                          @click="openSettings"
                        >
                          <feather-icon size="16" icon="EditIcon" />
                          <span> {{ $t("Edit") }} </span>
                        </b-dropdown-item>
                        <b-dropdown-item
                          link-class="d-flex align-items-center gap-1"
                          @click="openSettings"
                        >
                          <feather-icon size="16" icon="TrashIcon" />
                          <span> {{ $t("Delete") }} </span>
                        </b-dropdown-item>
                      </b-nav-item-dropdown>
                    </div> -->
                  </div>
                  <div class="comment-body">
                    <div class="comment-text preview-mode">
                      <div
                        v-if="user.id == comment.userId"
                        :class="{
                          'is-archived': comment.isArchived == 1,
                        }"
                      >
                        <MarkdownEditor
                          :content="comment.text || ''"
                          :builder-images="comment.images || []"
                          :editable="false"
                        />
                      </div>
                      <p
                        v-if="errorTicketCommentId == comment.id"
                        style="color: red"
                      >
                        {{ $t("This form is required to be filled") }}
                      </p>
                      <div
                        class="d-flex align-items-center justify-content-between w-100 mt-2"
                      >
                        <p>
                          <span v-if="comment.createdAt">
                            {{ formatTime(comment.createdAt || "") }}
                          </span>
                          <span v-else>
                            {{ $t("Accounted Time") }}:
                            {{ $t("Not Set") }}
                          </span>
                          {{
                            comment.isArchived === 1
                              ? " - " + $t("Is Deleted")
                              : ""
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="comment-footer">
                    <div class="row mt-1">
                      <div
                        class="col-md-4"
                        v-for="attachment in comment.attachments"
                        :key="attachment.id"
                      >
                        <div class="attachment-card">
                          <div class="d-flex align-items-center gap-2">
                            <span
                              class="text-primary"
                              target="_blank"
                              style="cursor: pointer"
                              @click="
                                downloadAttachments(
                                  attachment.id,
                                  attachment.viewableName
                                )
                              "
                            >
                              <span v-if="isImageFile(attachment.viewableName)">
                                <img
                                  src="@/assets/images/extensions/img.png"
                                  alt="Image"
                                />
                              </span>
                              <span
                                v-else-if="
                                  isImageFile(attachment.viewableName, 'jpg')
                                "
                              >
                                <img
                                  src="@/assets/images/extensions/jpg.png"
                                  alt="JPG"
                                />
                              </span>
                              <span
                                v-else-if="
                                  isImageFile(attachment.viewableName, 'pdf')
                                "
                              >
                                <img
                                  src="@/assets/images/extensions/pdf.png"
                                  alt="PDF"
                                />
                              </span>
                              <span v-else>
                                <img
                                  src="@/assets/images/extensions/img.png"
                                  alt="Image"
                                />
                              </span>
                            </span>
                            <div class="">
                              <a
                                @click="
                                  downloadAttachments(
                                    attachment.id,
                                    attachment.viewableName
                                  )
                                "
                                href="javascript:void(0);"
                                class="font-weight-semibold"
                              >
                                {{ attachment.viewableName }}</a
                              >
                              <p class="mb-0">
                                {{ attachment.size }}
                              </p>
                            </div>
                          </div>
                          <div class="d-flex align-items-center gap-2">
                            <span
                              class="cursor-pointer"
                              @click="
                                downloadAttachment(
                                  attachment.id,
                                  attachment.viewableName
                                )
                              "
                            >
                              <feather-icon size="18" icon="DownloadIcon" />
                            </span>
                            <!-- <span
                              class="cursor-pointer"
                              @click="removeAttachment(attachment.id)"
                            >
                              <feather-icon size="18" icon="TrashIcon" />
                            </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <p
                v-if="(comments.length || 0) < ticketCommentsTotal"
                class="text-align-center"
                ref="load"
              >
                {{ $t("Loading more ...") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="img-prev-modal" v-if="isPrevShow">
      <div class="modal-content">
        <img class="img-prev" :src="isImgPreview" alt="" />
        <div class="close-modal" @click="closeImgPrev">
          <feather-icon size="18" icon="XIcon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Quill } from "vue-quill-editor/src";
import PageHeader from "@/components/PageHeader.vue";
import ElLabel from "@/components/ElLabel.vue";
import vueDropzone from "vue2-dropzone";
import FileService from "@/services/file.service";
import ShowFiles from "@/components/ticketSystem/show-files.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import MarkdownEditor from "@/components/MarkdownEditor.vue";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import usersStoreMixin from "@/mixins/usersStoreMixin";
import ElementsIcon from "@/components/elements/Icon.vue";

export default {
  mixins: [usersStoreMixin],
  components: {
    LoadingButton,
    ShowFiles,
    ElLabel,
    PageHeader,
    vueDropzone,
    TextInput,
    MarkdownEditor,
    MultiSelectInput,
    ElementsIcon,
  },
  data() {
    return {
      reactionTimeEnums: {
        low: "reactionTimeLow",
        normal: "reactionTimeHigh",
        high: "reactionTimeLowUrgent",
      },
      slaLevel: null,
      slaServiceTime: null,
      ticketCommentsTotal: 0,
      errorTicketCommentId: null,
      ticketCommentId: null,
      isTicketFormReadonly: true,
      draftedAnswers: [],
      selectedTicketForm: null,
      commentType: 0,
      activeTab: 0,
      formBuilderAnswers: [],
      isFirstComment: false,
      firstAnswerForms: [],
      title: "",
      submitted: false,
      commentText: "",
      commentBtn: true,
      isPrevShow: false,
      isImgPreview: "",
      hasCommentError: false,
      observer: new IntersectionObserver(this.infiniteScroll),
      ticketForm: {
        text: "",
        time: 0,
        ticketId: this.$route.params.id,
        visibility: "",
      },
      isDwonloadingFile: false,
      perPage: 10,
      ticket: {
        title: "",
        state: "",
        priority: "",
        type: "",
        software: "",
        accounting: "",
        visibility: "",
        createdAt: "",
      },
      form: {
        title: "",
        priority: "",
        accounting: "",
        createdAt: "",
      },
      reactionTimeEnums: {
        low: "reactionTimeLow",
        normal: "reactionTimeHigh",
        high: "reactionTimeUrgent",
      },
      isLoading: false,
      isImageUploaded: false,
      enableAttachment: false,
      commentFile: "",
      commentFiles: [],
      comments: [],
      isCloseTicket: true,
      usernames: [],
      isCardVisible: false,
      editorOption: {
        theme: "snow",
        placeholder: this.$t("Insert text here..."),
      },
      appendedForm: null,
      formFiller: null,
      isFormMandatoryToAnswer: false,
      isFormMandatoryToResolveTicket: false,
      formFillerOptions: [
        { value: "creator", text: "Creator" },
        { value: "assignee", text: "Assignee" },
        { value: "everyone", text: "Everyone" },
      ],
      isReportedCase: false,
      images: [],
    };
  },
  computed: {
    ...mapGetters(["showLoader"]),
    ...mapGetters("tickets", ["tickets"]),
    ...mapGetters("ticketComments", []),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("users", ["users", "userProfiles"]),
    items() {
      return [
        {
          text: this.$t("Dental Twin"),
          to: "/",
        },
        {
          text: this.$t("Tickets"),
          to: "/tickets",
        },
        {
          text: this.$t("Edit"),
          active: true,
        },
      ];
    },
    // returns true if due date has passed
    hasDueDatePassed() {
      try {
        if (!this.ticket.dueDate) {
          return false;
        }
        let currentDate = new Date();
        let dueDate = new Date(this.ticket.dueDate);
        return currentDate > dueDate;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    /**
     * calculates the deadline date based on the from and to times from SLA service time for the linked attachment and the created at date
     */
    calculateDeadline() {
      if (!this.ticket.createdAt || !this.slaLevel || !this.slaServiceTime) {
        return "";
      }
      let deadLine = "";
      let fromDate = new Date(this.ticket.createdAt); // set to the created at date
      let days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      // if the current day exists in sla service time days then the current day is valid
      const isValidDay = (this.slaServiceTime?.days ?? []).some(
        (day) => day.id == days[fromDate.getDay()]
      );
      if (!isValidDay) {
        // if the current day is not valid then we iterate over the week and find a valid day and set fromDate to that day
        let date = new Date(this.ticket.createdAt);
        date.setDate(date.getDate() + 1);
        while (fromDate.getDay() !== date.getDay()) {
          if (
            (this.slaServiceTime?.days ?? []).some(
              (day) => day.id == days[date.getDay()]
            )
          ) {
            fromDate = date;
            break;
          }
          date.setDate(date.getDate() + 1);
        }
      }
      const fromTokens = (this.slaServiceTime?.from ?? "").split(":"); // get the hour and minute but spliting with ":"
      // set the hour and minute
      fromDate.setHours(fromTokens?.[0] ?? 0);
      fromDate.setMinutes(fromTokens?.[1] ?? 0);
      fromDate.setSeconds(fromTokens?.[2] ?? 0);
      const toDate = new Date(fromDate);
      const toTokens = (this.slaServiceTime?.to ?? "").split(":"); // get the hour and minute but spliting with ":"
      // set the hour and minute
      toDate.setHours(toTokens?.[0] ?? 0);
      toDate.setMinutes(toTokens?.[1] ?? 0);
      toDate.setSeconds(toTokens?.[2] ?? 0);
      // get reaction time from the sla level
      const reactionTime =
        this.slaLevel?.[this.reactionTimeEnums[this.form.priority]] ?? 0;
      if (new Date(this.ticket.createdAt) < fromDate) {
        const fromPlusReactionTime = this.addHours(fromDate, reactionTime);
        if (new Date(fromPlusReactionTime) <= toDate) {
          deadLine = fromPlusReactionTime;
        } else {
          // if the time limit has been exceeded then find the valid day
          let addedHours = 24;
          let date = new Date(fromDate);
          date.setDate(date.getDate() + 1);
          let remainingTimeNextDay =
            +reactionTime - this.getHoursDiffBetweenDates(fromDate, toDate);
          while (
            fromDate.getDay() !== date.getDay() ||
            remainingTimeNextDay >
              this.getHoursDiffBetweenDates(fromDate, toDate)
          ) {
            if (
              (this.slaServiceTime?.days ?? []).some((day) => {
                return day.id == days[date.getDay()];
              })
            ) {
              if (
                remainingTimeNextDay <=
                this.getHoursDiffBetweenDates(fromDate, toDate)
              ) {
                break;
              }
              remainingTimeNextDay -= this.getHoursDiffBetweenDates(
                fromDate,
                toDate
              );
            }
            date.setDate(date.getDate() + 1);
            addedHours += 24;
          }

          deadLine = this.addHours(
            fromDate,
            addedHours + +remainingTimeNextDay
          );
        }
      } else {
        deadLine = this.addHours(
          new Date(this.ticket.createdAt),
          +reactionTime
        );
      }
      return deadLine;
    },
    dropzoneOptions() {
      const component = this; // Capture the component context
      return {
        url: process.env.backEndUrl + "/api/convert-file-to-base64",
        autoProcessQueue: false,
        maxFiles: 5,
        accept: function (file, done) {
          done();
        },
        acceptedFiles:
          ".jpg,.jpeg,.png,.pdf,.csv,.doc,.docx,.ppt,.pptx,.xls,.xlsx",
        dictDefaultMessage: this.$t("Click or drag file here to upload"),
        addRemoveLinks: true,
        init: function () {
          this.on("addedfile", function (file) {
            component.processFile(file); // Use the component context
          });
        },
      };
    },
  },
  methods: {
    updateDropzoneMessage() {
      if (this.$refs.myDropzone && this.$refs.myDropzone.dropzone) {
        const dropzoneInstance = this.$refs.myDropzone.dropzone;
        dropzoneInstance.options.dictDefaultMessage = this.$t(
          "Click or drag file here to upload"
        );
        dropzoneInstance.element.querySelector(".dz-message").textContent =
          this.$t("Click or drag file here to upload");
      }
    },
    addCommentImages(event) {
      this.images = event;
    },
    customLabel(props) {
      return `${props?.first_name || ""} ${props?.last_name || ""}`;
    },
    /**
     * calculates the difference in hours between two dates
     * @param {dateInitial} start date
     * @param {dateFinal} end date
     */
    getHoursDiffBetweenDates(dateInitial, dateFinal) {
      return (dateFinal - dateInitial) / (1000 * 3600);
    },
    /**
     * adds hours to a date
     * @param {date} the date
     * @param {hours} hours to be added
     */
    addHours(date, hours) {
      try {
        const d = new Date(date);
        d.setHours(d.getHours() + +hours);
        return (
          d.toISOString().slice(0, 10) + " " + d.toTimeString().slice(0, 8)
        );
      } catch (e) {
        return date;
      }
    },
    async assignDataComments(response) {
      if (!!response) {
        this.ticketCommentsTotal = response?.length ?? 0;
        this.comments = response ?? [];
        await this.getUserProfiles(
          this.comments.map((comment) => comment.userId)
        );
      }
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.perPage += 10;
        const responseComments = await this.$store.dispatch(
          "ticketComments/list",
          {
            params: {
              ticketId: this.$route.params.id,
              perPage: this.perPage,
              sortOrder: "desc",
              sortBy: "created_at"
            },
          }
        );
        await this.assignDataComments(responseComments?.data?.data);
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    downloadFiles(array) {
      FileService.downloadFiles(array);
    },
    async refresh() {
      try {
        this.$store.commit("showLoader", true);
        const response = await this.$store.dispatch(
          "tickets/show",
          this.$route.params.id
        );

        this.ticket = response?.data?.data ?? {};
        this.form.priority = this.ticket.priority;
        this.form.status = this.ticket.status;
        this.form.dueDate = this.ticket.dueDate;
        this.form.title = this.ticket.title;
        if (this.ticket && this.ticket.case) this.isReportedCase = true;
        const responseComments = await this.$store.dispatch(
          "ticketComments/list",
          {
            params: {
              perPage: this.perPage,
              sortOrder: "desc",
              ticketId: this.$route.params.id,
              sortBy: "created_at"
            },
          }
        );
        await this.assignDataComments(responseComments?.data?.data);
        await this.$store.dispatch("users/list");
        const usersToBeFetched = [this.ticket.userId];
        await this.getUserProfiles(usersToBeFetched);
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    formatTime(timestamp) {
      const utcDate = new Date(timestamp);
      const offset = utcDate.getTimezoneOffset() * 60000; // Offset in milliseconds
      const localTimestamp = utcDate.getTime() - offset;
      const localDate = new Date(localTimestamp);
      return localDate.toLocaleString();
    },
    htmlFromText(text) {
      try {
        let article = document.createElement("article");
        let quill = new Quill(article);
        quill.setContents(JSON.parse(text));
        return quill.root.innerHTML;
      } catch (err) {
        return md.render(text);
      }
    },
    getInitials(name) {
      const tokens = name?.split(" ");
      if (tokens)
        return `${tokens?.[0]?.[0] ?? ""}${
          tokens?.[1]?.[0] ?? ""
        }`.toUpperCase();
      else return "";
    },
    processFile(file) {
      var reader = new FileReader();
      reader.onload = (event) => {
        const fileName = file.name;
        const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        const dataUri = event.target.result;
        const parts = dataUri.split(",");
        if (parts.length === 2) {
          let file = {
            name: fileName,
            type: fileExtension,
            size: `${fileSizeMB} MB`,
            base64: parts?.[1] ?? "",
          };
          this.commentFiles.push(file);
        }
      };
      reader.readAsDataURL(file);
      this.$refs.myDropzone.removeAllFiles();
    },
    async createComment(baypass = true) {
      try {
        if (this.commentText == "" || this.commentText == null) {
          this.hasCommentError = true;
          return false;
        }
        let attachments = [];
        this.commentBtn = false;
        if (!!this.commentFiles)
          attachments = Array.from(new Set(this.commentFiles));

        this.isCloseTicket = baypass;
        this.$store.commit("showLoader", true);
        await this.$store
          .dispatch("ticketComments/create", {
            text: this.commentText,
            images: this.images,
            ticketId: this.$route.params.id,
            visibility: "public",
            attachments: attachments,
            userId: localStorage.getItem("user_id"),
          })
          .then(async (response) => {
            await this.refresh();
            this.commentText = "";
            this.images = [];
            this.commentFiles = [];
            this.appendedForm = null;
            this.formFiller = null;
            this.isFormMandatoryToAnswer = false;
            this.isFormMandatoryToResolveTicket = false;
          })
          .catch((error) => {
            this.commentText = "";
            this.commentFiles = [];
            this.images = [];
            this.showError(error);
          })
          .finally(() => {
            this.$store.commit("showLoader", false);
          });
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
        this.commentBtn = true;
        this.hasCommentError = false;
      }
    },
    async downloadAttachments(id, fileName) {
      if (!this.isDwonloadingFile) {
        try {
          this.$store.commit("showLoader", true);
          this.isDwonloadingFile = true;
          let dataURI;
          // Make an Axios GET request to fetch the Base64 image data
          const response = await this.$store.dispatch(
            "ticketComments/downloadAttachment",
            id
          );
          // Extract the Base64 data from the response
          const base64Data = response?.data?.base64;
          // Create a Data URI for the Base64 image
          if (this.isImageFile(fileName)) {
            //dataURI = "data:image/jpg;base64," + base64Data; // Adjust the MIME type as needed
            this.isImgPreview = base64Data;
            this.isPrevShow = true;

            this.isDwonloadingFile = false;
            return;
          } else {
            //dataURI = "data:application/pdf;base64," + base64Data;
          }
          // Create a temporary anchor element for downloading
          const a = document.createElement("a");
          a.href = base64Data;
          a.download = fileName || "download"; // Use 'download' as the default filename if fileName is not provided
          a.style.display = "none";

          // Append the anchor element to the document body
          document.body.appendChild(a);

          // Trigger a click event on the anchor element to start the download
          a.click();

          // Remove the anchor element
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error downloading image:", error);
        } finally {
          this.$store.commit("showLoader", false);
        }

        this.isDwonloadingFile = false;
      }
    },
    async downloadAttachment(id, fileName) {
      if (!this.isDwonloadingFile) {
        try {
          this.$store.commit("showLoader", true);
          this.isDwonloadingFile = true;

          // Fetch the Base64 data from the API
          const response = await this.$store.dispatch(
            "ticketComments/downloadAttachment",
            id
          );

          // Extract Base64 data and determine MIME type
          const base64Data = response?.data?.base64;
          if (!base64Data) {
            throw new Error("No data received for the attachment.");
          }

          // Determine MIME type based on file extension
          const mimeType =
            this.getMimeType(fileName) || "application/octet-stream";

          // Create a Data URI
          let dataURI;
          if (!base64Data.includes(mimeType)) {
            dataURI = `data:${mimeType};base64,${base64Data}`;
          } else {
            dataURI = base64Data;
          }

          // Create a temporary anchor element for downloading
          const a = document.createElement("a");
          a.href = dataURI;
          a.download = fileName || "download"; // Use 'download' as the default filename if fileName is not provided
          a.style.display = "none";

          // Append the anchor to the document body
          document.body.appendChild(a);

          // Trigger a click event on the anchor element to start the download
          a.click();

          // Remove the anchor element
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error downloading the file:", error);
        } finally {
          this.$store.commit("showLoader", false);
          this.isDwonloadingFile = false;
        }
      }
    },

    // Helper method to determine MIME type
    getMimeType(fileName) {
      const extension = fileName.split(".").pop().toLowerCase();
      const mimeTypes = {
        jpg: "image/jpeg",
        jpeg: "image/jpeg",
        png: "image/png",
        pdf: "application/pdf",
        doc: "application/msword",
        docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        txt: "text/plain",
        csv: "text/csv",
        xls: "application/vnd.ms-excel",
        xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        zip: "application/zip",
      };

      return mimeTypes[extension] || "application/octet-stream"; // Default to binary data
    },

    async changeStatus(status) {
      let title = "";
      let btnLabel = "";
      if (status === "resolved") {
        title = this.$t("Do you want to closed this ticket?");
        btnLabel = this.$t("Yes Closed it!");
      } else {
        title = this.$t("Do you want to Re-Open this ticket?");
        btnLabel = this.$t("Yes Re-open it!");
      }

      Swal.fire({
        title: title,
        text: this.$t("You can't revert your action"),
        type: "warning",
        showCancelButton: true,
        confirmButtonText: btnLabel,
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          this.isLoading = true;

          await this.handleReOpenTicket({
            id: this.ticket?.id,
            status: status,
          });

          const response = await this.showTicket(this.$route.params.id);
          this.ticket = response?.data?.data?.tickets ?? {};
          this.isLoading = false;
        }
      });
    },
    ready() {
      try {
        this.$refs.quillEditor.setContents(JSON.parse(this.comment));
      } catch (e) {
        this.$refs.quillEditor.setContents(this.comment ?? "");
      }
    },
    deleteUpload(index) {
      this.commentFiles.splice(index, 1);
    },
    openDropZone() {
      this.enableAttachment = true;
    },
    isImageFile(fileName) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const fileExtension = fileName.split(".").pop().toLowerCase();
      return imageExtensions.includes(fileExtension);
    },
    isFile(fileName, fileType) {
      const pdfExtensions = [fileType];
      const fileExtension = fileName.split(".").pop().toLowerCase();
      return pdfExtensions.includes(fileExtension);
    },

    openImgPrev() {
      this.isPrevShow = true;
    },
    closeImgPrev() {
      this.isPrevShow = false;
    },
    async updateTicket() {
      this.$store.commit("showLoader", true);
      this.submitted = true;
      try {
        let response = await this.$store.dispatch("tickets/update", {
          id: this.$route.params.id,
          data: {
            ...this.form,
          },
        });
        this.$router.push("/tickets");
      } catch (e) {
        console.log(e);
      } finally {
        this.$store.commit("showLoader", false);
        this.submitted = false;
      }
    },
  },
  async mounted() {
    this.updateDropzoneMessage(); // Set initial message
    this.$watch("$i18n.locale", () => {
      this.updateDropzoneMessage(); // Update message when language changes
    });
    await this.refresh();
  },
  watch: {
    ticketCommentsTotal() {
      this.$nextTick(() => {
        if (this.$refs.load) {
          this.observer?.observe(this.$refs.load);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.chat {
  width: 100%;
  position: relative;
}
.card-right {
  padding: 10px;
  border: 1px solid #6c757d;
  border-radius: 6px;
}
.ql-container.ql-snow {
  min-height: 180px !important;
}

.v-md-editor {
  box-shadow: none;
}

.ticket-right-content {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      width: 33%;

      h4 {
        width: 40%;
        margin: 0;
        color: #11253e;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
      }

      p {
        margin: 0;
      }
    }
  }

  h3 {
    font-size: 18px;
  }
}

.custom-file-upload {
  margin: 0px;
  cursor: pointer;

  i {
    font-size: 22px;
  }
}

.dropzone-data {
  background: #f5f5f5;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add_comm {
  overflow: hidden;
  textarea {
    border: none;
  }

  .add_head {
    padding: 10px 15px;
    display: flex;
    align-items: center;
  }

  .add_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      color: #6c757d;
    }
  }
}

.card-body {
  position: relative;
}

.card-right {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 170px);

  &::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
    border-radius: 5px;
  }
}

.reply_btn {
  color: #fff;
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  background: #269dc9;
}

.comment {
  background: #fff;
  border-bottom: 1px solid #e7eaed;
  padding: 15px 0px 0px 0px;
}

.dropdown-action:hover {
  background-color: #2957a4;
  color: white;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.comment {
  position: relative;
}

.comment:hover svg {
  display: block !important;
}

.talk-bubble {
  margin: 20px 0px 10px;
  display: inline-block;
  position: relative;
  /* width: 200px; */
  height: auto;
  background-color: white;
}

.round {
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
}

.tri-right.border.left-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.left-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -22px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 22px solid;
  border-color: white transparent transparent transparent;
}

.error-border {
  border: 1px solid red;
}

/* Right triangle, left side slightly down */
.tri-right.border.left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -22px;
  right: auto;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -12px;
  right: auto;
  top: 22px;
  bottom: auto;
  border: 12px solid;
  border-color: white white transparent transparent;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: -8px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 32px solid;
  border-color: transparent transparent transparent #666;
}

.tri-right.btm-left:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 0px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 22px solid;
  border-color: transparent transparent transparent white;
}

/*Right triangle, placed bottom left side slightly in*/
.tri-right.border.btm-left-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 30px;
  right: auto;
  top: auto;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}

.tri-right.btm-left-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  right: auto;
  top: auto;
  bottom: -20px;
  border: 12px solid;
  border-color: white transparent transparent white;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 30px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 38px;
  bottom: -20px;
  border: 12px solid;
  border-color: white white transparent transparent;
}

/*
	left: -8px;
  right: auto;
  top: auto;
	bottom: -40px;
	border: 32px solid;
	border-color: transparent transparent transparent #666;
	left: 0px;
  right: auto;
  top: auto;
	bottom: -20px;
	border: 22px solid;
	border-color: transparent transparent transparent white;

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -8px;
  bottom: -40px;
  border: 20px solid;
  border-color: #666 #666 transparent transparent;
}

.tri-right.btm-right:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 0px;
  bottom: -20px;
  border: 12px solid;
  border-color: white white transparent transparent;
}

.comment-image {
  display: flex;
  justify-content: right;
}

.comment-image img {
  height: 40px;
}

/* Right triangle, right side slightly down*/
.tri-right.border.right-in:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: 30px;
  bottom: auto;
  border: 20px solid;
  border-color: #666 transparent transparent #666;
}

.tri-right.right-in:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -12px;
  top: 22px;
  bottom: auto;
  border: 12px solid;
  border-color: white transparent transparent white;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -40px;
  top: -8px;
  bottom: auto;
  border: 32px solid;
  border-color: #666 transparent transparent transparent;
}

.tri-right.right-top:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -20px;
  top: 0px;
  bottom: auto;
  border: 20px solid;
  border-color: white transparent transparent transparent;
}

.action {
  li {
    list-style: none;
    margin: 0;
    padding: 0;
    .dropdown-toggle {
      svg {
        width: 22px;
        height: 22px;
        path {
          fill: #fff;
          stroke: #fff;
        }
      }
      &::after {
        display: none;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

/* talk bubble contents */
.talktext {
  padding: 0 0.7rem 0.7rem 0.7rem;
  text-align: left;
  line-height: 1.5em;
}

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
  img {
    width: 100%;
  }
}

.v-md-editor__tooltip {
  display: none !important;
}

.is-archived {
  text-decoration: line-through;
}
.ticket-logs {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  ul {
    list-style: disc;
  }
  li {
    list-style: disc;
    font-weight: 600;
  }
}
:deep(.markdown-body) {
  height: 350px;
}

:deep(.tab-content) {
  padding: 0;
}

:deep(.md-editor-footer) {
  display: none;
}

.editor :deep(.md-editor-preview-wrapper) {
  max-height: 300px !important;
  min-height: 300px !important;
}

:deep(.md-editor) {
  max-height: 300px !important;
}

.text-align-center {
  text-align: center;
}
:deep(.dropzone) .dz-preview {
  z-index: 1 !important;
}
.comment {
  background: transparent;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border: none;
  }
  .comment-header {
    min-height: 50px;
    position: relative;
    padding: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .comment-user {
      // position: relative;
      height: 100%;
      .comment-user-img {
        position: absolute;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        left: 0;
        top: 8px;
      }
      .comment-user-title {
        padding-left: 45px;
        h3 {
          font-size: 16px;
        }
      }
    }
  }
  .comment-body {
    padding-left: 45px;
    .invoice-details {
      border: 1px solid #6c757d90;
      border-radius: 6px;
      padding: 10px;
      h4 {
        font-weight: 600;
        font-size: 18px;
      }
      h3 {
        font-size: 16px;
        font-weight: 600;
      }
    }
    .comment-text {
      background: #e7eaed;
      border: 1px solid #6c757d90;
      border-radius: 6px;
      padding: 10px;
    }
  }
  .comment-footer {
    padding: 0 0 10px;
    padding-left: 45px;
  }
}
</style>
<style lang="scss">
.dropdown-btn {
  color: #fff;
  svg {
    fill: #fff;
  }
  &::after {
    display: none !important;
  }
}
body.dark-layout
  .preview-mode
  .mavonEditor
  .v-note-wrapper
  .v-note-show
  .v-show-content {
  border: none !important;
  background: transparent !important;
}
.preview-mode .markdown-body {
  border: none !important;
  background: transparent !important;
}
.preview-mode {
  .mavonEditor .v-note-wrapper .v-note-show .v-show-content {
    background: transparent !important;
  }
}
.preview-mode .v-note-op {
  display: none !important;
}
.preview-mode .v-note-edit {
  display: none !important;
}
.preview-mode .v-note-panel {
  display: block !important;
}
.preview-mode .v-show-content {
  overflow: auto !important;
  background-color: white !important;
  padding: 0 !important;
}
.preview-mode .v-note-show {
  width: 100% !important;
}
.preview-mode .v-note-wrapper {
  z-index: 1;
  min-height: auto;
  padding: 0;
}

.preview-mode .v-note-wrapper p {
  margin-bottom: 0;
}

.preview-mode .markdown-body {
  height: auto;
  box-shadow: none !important;
  background-color: transparent !important;
}
</style>
